import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileDelete, FileDownloadBlob, FileDownloadStream } from '../api';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { useSnackbar, useFileView } from '../hooks';
import { File } from '../utils/dataTypes';
import Loader from './Loader';

export default function FileItem({
  file,
  disabled = false,
}: {
  file: File;
  disabled?: boolean;
}) {
  const [fileData, setFileData] = React.useState<string>('');
  const [isFileDataLoading, setIsFileDataLoading] =
    React.useState<boolean>(false);

  const queryClient = useQueryClient();
  const setSnackbarMessage = useSnackbar();
  const [, setModalFileData] = useFileView();

  const { mutate, isLoading } = useMutation(FileDelete, {
    onSuccess: () => {
      setSnackbarMessage({
        severity: 'success',
        message: 'Deleted',
      });
      queryClient.invalidateQueries(['files']);
    },
    onError: (error: Error) => {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    },
  });

  React.useEffect(() => {
    let url: string;
    (async () => {
      setIsFileDataLoading(true);
      const fileData = await FileDownloadBlob(file.id);
      setIsFileDataLoading(false);
      url = URL.createObjectURL(fileData);
      setFileData(url);
    })();
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, []);

  const handleDelete = () => {
    const result = confirm('Are you sure you want to delete file');
    if (result) {
      mutate(`${file.id}`);
    }
  };

  const handleImgClick = () => {
    if (fileData) {
      setModalFileData({ file, fileData });
    }
  };

  const handleSaveFile = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let handle: any;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      handle = await window.showSaveFilePicker({
        suggestedName: file.filename,
      });
    } catch {}
    if (!handle) {
      return;
    }
    try {
      const writableStream = await handle.createWritable();
      await (await FileDownloadStream(file.id)).pipeTo(writableStream);
    } catch (error) {
      setSnackbarMessage({
        severity: 'error',
        message: error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
  };

  return (
    <Paper sx={{ height: '240px', width: '210px', backgroundColor: 'unset' }}>
      <Box
        sx={{
          width: '100%',
          padding: '5px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <a
          href="#"
          target="_blank"
          onClick={handleSaveFile}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >{`${file.comment ? file.comment : file.filename}`}</a>
        <IconButton
          aria-label="delete file"
          onClick={handleDelete}
          sx={{ padding: 0 }}
          disabled={disabled}
        >
          <DeleteForever />
        </IconButton>
      </Box>
      {!fileData ? null : (
        <>
          <object
            data={fileData}
            type={file.contentType}
            style={{
              height: '200px',
              width: '200px',
              margin: 'auto',
              display: 'block',
            }}
            title={`${file.filename}`}
          />
          <Box
            onClickCapture={handleImgClick}
            style={{
              height: '200px',
              width: '200px',
              position: 'relative',
              top: '-200px',
              left: '5px',
              cursor: 'pointer',
            }}
          />
        </>
      )}
      {!isFileDataLoading ? null : (
        <CircularProgress
          sx={{ display: 'block', margin: 'auto', marginTop: '50px' }}
        />
      )}
      <Loader visible={isLoading} />
    </Paper>
  );
}
