import * as React from 'react';
import { File } from '../utils/dataTypes';

export type FileObject = {
  file: File;
  fileData: string;
} | null;

export const ModalFileViewContext = React.createContext<
  [fileData: FileObject, setFileData: (fileObject: FileObject) => void]
>(null!);

export const useFileView = function useFileView() {
  return React.useContext(ModalFileViewContext);
};
