import * as React from 'react';

import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { grey } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';

import { FileObject, ModalFileViewContext } from '../hooks';

export const ModalImgProvider = function ModalImgProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [fileObject, setFileObject] = React.useState<FileObject | null>(null);
  const handleCloseImg = (/*event: object, reason: string*/) => {
    setFileObject(null);
  };

  const handleSetFileObject = (fileObject: FileObject) =>
    setFileObject(fileObject);

  return (
    <ModalFileViewContext.Provider value={[fileObject, handleSetFileObject]}>
      {children}
      <Modal
        open={!!fileObject}
        onClose={handleCloseImg}
        closeAfterTransition
        aria-labelledby="modal-img"
        aria-describedby="modal-img"
        slotProps={{
          backdrop: {
            children: (
              <Close
                sx={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  height: '24px',
                  width: '24px',
                  color: grey[100],
                }}
                onClick={handleCloseImg}
              />
            ),
          },
        }}
      >
        <Slide direction="up" in={!!fileObject}>
          <object
            data={fileObject?.fileData}
            type={fileObject?.file.contentType}
            style={{
              margin: 'auto',
              display: 'block',
              marginTop: '2.5%',
              height: '90%',
              width: fileObject?.file.contentType.startsWith('image/')
                ? 'auto'
                : '90%',
              maxHeight: '90%',
              maxWidth: '90%',
              objectFit: 'contain',
              backgroundColor: grey[50],
            }}
            title={`${fileObject?.file.filename}`}
          />
        </Slide>
      </Modal>
    </ModalFileViewContext.Provider>
  );
};
